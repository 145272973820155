'use client'

import { useState, type ReactNode } from 'react'
import NextImage, { type ImageProps as NextImagePropsType } from 'next/image'
import Link, { type LinkProps } from 'next/link'
import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { USE_HTML_IMAGE_TAG } from 'config/constants'

export type ImagePropsType = NextImagePropsType & {
  linkprops?: LinkProps & { target?: string }
  fallbackImageSrc?: NextImagePropsType['src']
  containerProps?: BoxPropsType
}

export type RegularImagePropsType = {
  src: string
  alt?: string
  width?: number | string
  height?: number | string
  fill?: boolean
  style?: React.CSSProperties
  linkprops?: LinkProps & { target?: string }
  fallbackImageSrc?: string
  onError?: NextImagePropsType['onError']
}

const Image = ({
  linkprops,
  src,
  fallbackImageSrc,
  containerProps,
  ...imageProps
}: ImagePropsType) => {
  const [imageSrc, setImageSrc] = useState(src)

  const renderRegularImage = (): ReactNode => {
    const alteredImageProps: RegularImagePropsType = {
      src: imageSrc as RegularImagePropsType['src'],
      alt: imageProps?.alt || '',
      width: imageProps?.fill ? '100%' : imageProps?.width,
      height: imageProps?.fill ? '100%' : imageProps?.height,
      style: {
        maxWidth: '100%',
        objectFit: 'contain',
        display: 'block',
        ...imageProps?.style,
      },
      onError: () => {
        if (fallbackImageSrc) {
          setImageSrc(fallbackImageSrc)
        }
      },
    }

    return (
      <Box position={imageProps?.fill ? 'relative' : undefined} {...containerProps}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img {...alteredImageProps} />
      </Box>
    )
  }

  const renderImage = (): ReactNode => {
    const alteredImageProps: NextImagePropsType = {
      ...imageProps,
      src: imageSrc,
      style: {
        maxWidth: '100%',
        objectFit: 'contain',
        display: 'block',
        height: imageProps.fill ? undefined : imageProps.height,
        ...(imageProps.style ? imageProps.style : {}),
      },
      onError: () => {
        if (fallbackImageSrc) {
          setImageSrc(fallbackImageSrc)
        }
      },
    }

    return (
      <Box position={imageProps.fill ? 'relative' : undefined} {...containerProps}>
        <NextImage className="image" {...alteredImageProps} />
      </Box>
    )
  }

  const renderImageWithLink = () => {
    if (linkprops) {
      return <Link {...linkprops}>{renderImage()}</Link>
    }

    return USE_HTML_IMAGE_TAG ? renderRegularImage() : renderImage()
  }

  return <Box display="block">{renderImageWithLink()}</Box>
}

export default Image
